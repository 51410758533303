<template>
  <div class="packages_contacts">
    <b-row class="text-center">
      <b-col
        cols="12"
        class="title"
      >
        <h3 class="h3">
          Хочете більше розміщень або потрібна консультація професіонала ?
        </h3>
        <b-card-text class="mb-3">
          Ми можемо підібрати будь-який тематичний список каналів з тією кількістю охоплення, які потрібні під ваш проєкт чи завдання, та досягти найкращого результату від рекламної кампанії.
        </b-card-text>
      </b-col>
      <b-col md="4">
        <b-card class="shadow-none py-1">
          <b-avatar
            size="42"
            rounded
            variant="light-primary"
            class="mb-2"
          >
            <feather-icon
              icon="MessageCircleIcon"
              size="18"
            />
          </b-avatar>
          <a
            href="https://t.me/TeLeAds_Support_Bot"
            target="_blank"
          >
            @TeLeAds_Support_Bot
          </a>
          <span class="text-body">Технічна підтримка</span>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card class="shadow-none py-1">
          <b-avatar
            size="42"
            rounded
            variant="light-primary"
            class="mb-2"
          >
            <feather-icon
              icon="SendIcon"
              size="18"
            />
          </b-avatar>
          <a
            href="https://t.me/teleads"
            target="_blank"
          >
            @TeLeAds
          </a>
          <span class="text-body">Менеджер</span>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card class="shadow-none py-1">
          <b-avatar
            size="42"
            rounded
            variant="light-primary"
            class="mb-2"
          >
            <feather-icon
              icon="MailIcon"
              size="18"
            />
          </b-avatar>
          <a
            href="mailto:support@teleads.com.ua"
            target="_blank"
          >
            support@teleads.com.ua
          </a>
          <span class="text-body">Написати на пошту</span>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import GlobalMixin from '@mixins/GlobalMixin'

export default {
  mixins: [GlobalMixin],
}
</script>

<style lang="scss">
.packages_contacts {
  padding-bottom: 3em;

  @media (max-width: 700px) {
    padding-bottom: 2em;
  }

  h3 {
    max-width: 720px;
    font-size: 2.4em;
    text-align: center;
    display: block;
    margin: 2em auto 0.5em;
  }

  @media (max-width: 700px) {
    h3 {
      font-size: 1.8em;
    }
  }

  .card-text {
    max-width: 960px;
    margin-inline: auto;
    font-size: 1.28em;
    font-weight: 500;
    line-height: 1.5;
    color: #5e5873;
  }

  @media (max-width: 700px) {
    .card-text {
      font-size: 1em;
    }
  }

  .card {
    height: 100%;
    margin-bottom: 0;

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    a {
      margin-bottom: 0.5rem;
      font-weight: 500;
      font-size: 1.286rem;
      line-height: 1.2;
      color: #5e5873;
      word-break: break-all;
    }
  }

  @media (max-width: 700px) {
    .card {
      height: auto;
      margin-bottom: 2rem;
    }
  }
}
</style>
