<template>
  <div class="packages_page">
    <div class="block_1">
      <b-row class="align-items-center">
        <b-col
          cols="12"
          lg="6"
        >
          <div class="left_side">
            <h1 class="h1">
              Робимо запуск <b-badge variant="warning">
                рекламної кампанії
              </b-badge> в Telegram під ключ
            </h1>
            <h3>Персональний менеджер та готові тарифи допоможуть вам отримати швидкий та якісний результат.</h3>
            <div class="btn_c">
              <b-button
                variant="primary"
                size="lg"
                href="https://t.me/teleads"
                target="_blank"
                class="mb-2"
              >
                Замовити послугу
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <b-card>
            <div class="main_video">
              <video
                autoplay="autoplay"
                height="100%"
                loop="loop"
                muted="muted"
                preload="auto"
                width="100%"
              >
                <source
                  src="/video/home-new.mp4"
                  type="video/mp4"
                >
                Your browser does not support the video tag.
              </video>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <PackagesTariffs />
    <PackagesContact />
    <div class="block_2">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Переваги співпраці з TeLeAds
          </h3>
        </b-col>
        <b-col
          cols="12"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(item, i) in advantages"
              :key="i"
              icon="SunIcon"
              variant="success"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h5>{{ item }}</h5>
              </div>
            </app-timeline-item>
          </app-timeline>
          <b-button
            variant="warning"
            size="lg"
            href="https://t.me/teleads"
            target="_blank"
            class="mt-3"
          >
            Запустити рекламну кампанію
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="block_3">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Як ми працюємо?
          </h3>
        </b-col>
        <b-col
          cols="12"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(item, i) in workingSteps"
              :key="i"
              icon="ClockIcon"
              variant="secondary"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h5>{{ item }}</h5>
              </div>
            </app-timeline-item>
          </app-timeline>
          <b-button
            variant="warning"
            size="lg"
            href="https://t.me/teleads"
            target="_blank"
            class="mt-3"
          >
            Створити замовлення
          </b-button>
        </b-col>
      </b-row>
    </div>
    <PackagesTariffs />
    <div class="block_5">
      <h3>
        Біржа реклами в Телеграм TeLeAds: <br>відгуки клієнтів та власників каналів
      </h3>
      <b-row>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          md="10"
        >
          <b-carousel
            id="carousel-interval"
            no-hover-pause
            controls
            indicators
            :interval="5000"
          >
            <b-carousel-slide
              v-for="(block, index) in getChunkMessages"
              :key="index"
            >
              <template #img>
                <b-row>
                  <b-col
                    v-for="review in block"
                    :key="review.id"
                    cols="12"
                    md="6"
                  >
                    <b-card>
                      <h6 class="font-weight-bolder mb-25">
                        {{ review.title }}
                      </h6>
                      <b-card-text>
                        {{ review.created_at }}
                      </b-card-text>
                      <b-card-text>
                        {{ review.message }}
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </b-col>
        <b-col
          cols="12"
          md="1"
        />
        <b-col
          cols="12"
          class="p-2 "
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              v-if="!userData || canViewVerticalNavMenuLink({ action: 'index', resource: 'account' })"
              variant="primary"
              size="lg"
              :to="`/reviews`"
            >
              Показати всі відгуки
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <PackagesContact />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { useUtils as useAclUtils } from '@core/libs/acl'
import GlobalMixin from '@mixins/GlobalMixin'
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import PackagesTariffs from '@/views/packages/PackagesTariffs.vue'
import PackagesContact from '@/views/packages/PackagesContact.vue'
import _ from 'lodash'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    PackagesTariffs,
    PackagesContact,
    BCarousel,
    BCarouselSlide,
  },
  mixins: [GlobalMixin],
  metaInfo: {
    title: 'Розміщення реклами під ключ в телеграм-каналах | TeLeAds — біржа реклами в Телеграм',
    meta: [
      { vmid: 'description', name: 'description', content: 'Перевірена аудиторія у всіх каналах. Персональний супровід клієнтів та підбір каналів для розміщення реклами. Надійність і детальний аналіз ефективності реклами. ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      advantages: [
        'Персональний менеджер, який буде повністю займатися просуванням вашого проекту',
        'Суттєва перевага у часі: за допомогою повної автоматизації біржа полегшує процес пошуку та вибору каналів для розміщення рекламних публікацій в телеграмі',
        'Створення стратегії рекламної кампанії та індивідуальний план просування',
        'Якісний, швидкий та гарантований результат. Усі канали в каталозі є якісними та ефективними для розміщення реклами. Результат від реклами можна отримати вже у день замовлення. Ми зі своєї сторони гарантуємо повне розміщення ваших публікацій та найкращий ефект від реклами',
        'Аналіз рекламної кампанії та надання детального звіту',
      ],
      workingSteps: [
        'Ви обираєте свій тариф і ділитесь подробицями проєкту та цілями',
        'Менеджер зв\'язується з вами протягом 24 годин та уточнює деталі',
        'Ми розробляємо стратегію рекламної компанії та оформляємо технічне завдання',
        'Аналізуємо ваших конкурентів та створюємо план просування',
        'Підбираємо канали для розміщення реклами',
        'Узгоджуємо графік виходу реклами та креативи',
        'Контролюємо публікацію розміщень',
        'Аналізуємо кампанію та надаємо звіт',
        'Ви отримуєте необхідний результат по рекламній кампанії',
      ],
      messages: [],
    }
  },
  computed: {
    getChunkMessages() {
      return _.chunk(this.messages, 2)
    },
  },
  created() {
    this.fetchReviews()
  },
  methods: {
    fetchReviews() {
      this.$http.get('/api/promo/messages/', {
        params: {
          per_page: 10,
        },
      })
        .then(response => {
          this.messages = response.data.data
        })
    },
  },
  setup() {
    const { canViewVerticalNavMenuLink } = useAclUtils()
    return {
      canViewVerticalNavMenuLink,
    }
  },
}
</script>

<style lang="scss">
.packages_page {
  .content-header {
    display: none;
  }

  .block_1 {
    h1 {
      margin:0 0 1em;
      font-size: 3em;
    }

    h3 {
      margin:0 0 2em;
    }

    @media (max-width: 700px) {
      h1 {
        font-size: 2em;
      }
    }
  }

  .block_2 {
    background: var(--primary);
    margin-top: 2em;
    border-radius: 0.428rem;
    padding: 3em;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 3em;

      h3 {
        font-size: 3em;
        color: #fff;
        font-weight: bold;
      }
    }

    h5 {
      color:#fff;
    }

    @media (max-width: 700px) {
      padding: 1em;

      .title {
        h3 {
          font-size: 2em;
        }
      }
    }
  }

  .block_3 {
    margin-top: 3em;
    padding: 3em;
    background: #fff;

    @media (max-width: 700px) {
      margin-top: 2em;
      padding: 1em;
    }

    h3 {
      font-size: 3em;
      text-align: center;
      display: block;
      margin: 0 0 1em;
      font-weight: bold;
    }

    .card {
      &.success h1,
      &.success h2,
      &.success h3,
      &.success h4,
      &.success h5,
      &.success h6,
      &.success span,
      &.success p {
        color: #fff !important;
      }

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          gap:1em;
        }

        .right {
          font-size: 2em;
        }
      }

      h4 {
        font-size: 2em;
        font-weight: bold;
        margin: 0 0 2em;
        display: block;
      }
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 2em;
      }
    }
  }

  .block_5 {
    h3 {
      margin: 2em auto 1em;
      font-size: 2.4em;
      text-align: center;
      display: block;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }
  }
}

@import "~@core/scss/base/pages/app-ecommerce.scss";
//@import "https://telega.in/assets/home/application-0aa30cd3fcd937f1f1f57949a12b769010c73736d5e4584cb9ac1cc240f4d635.css";
</style>
